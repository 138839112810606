import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/pusher'
import permissions from '@/auth/permissions'
import activeCompany from '@/auth/activeCompany'
import profile from '@/auth/profile'
import text from '@/views/components/text'
import classifiers from '@/auth/classifiers'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Active company
Vue.use(activeCompany)

// Permissions
Vue.use(permissions)

// Classifiers
Vue.use(classifiers)

// Profile
Vue.use(profile)

// Truncate filter
Vue.use(text)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.prototype.$globalEvent = new Vue()

const startVue = () => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
}

const fetchUserData = async () => {
  await Promise.all([
    Vue.prototype.$activeCompany().fetchCompany(),
    Vue.prototype.$permissions().fetchAccess(),
    Vue.prototype.$classifiers().fetchClassifiers(),
  ])
  startVue() // Only call startVue once, to avoid multiple app renders
}

// Load in prerequired data
Vue.prototype.$profile().fetchProfile(fetchUserData, startVue)
